function showResults(value, button) {
	button.prop("disabled", true).addClass("loading");

	$.ajax({
		url: mldb_results_ajax_obj.ajaxurl,
		data: {
			action: "ajax_mldb_results",
			selected_city: value,
		},
		success: function (response) {
			$(".blejackiskroz").html(response);
			button.prop("disabled", false).removeClass("loading");
		},
	});
}

$(function () {
	if ($(".mldb-search").length > 0) {
		var debounce;

		const button = $(this).find('button[type="submit"]');
		const form = $(this);

		$(".mldb-search input").on("keyup", function () {
			clearTimeout(debounce);

			const wrapper = $(this).parents(".ch-custom-input");

			wrapper.addClass("searching");

			debounce = setTimeout(function () {
				var value = $(".mldb-search input").val();

				if (value) {
					$.ajax({
						url: mldb_search_ajax_obj.ajaxurl,
						data: {
							action: "ajax_mldb_search",
							search_term: value,
						},
						success: function (response) {
							var cities = JSON.parse(response);

							// Remove the existing ul element
							$(".mldb-search .ch-custom-input ul").remove();

							wrapper.removeClass("searching");

							// Create a new ul element
							var ul = $("<ul class='autocomplete-dropdown'>");

							// Append an li element for each city in the list
							cities.forEach(function (city) {
								var button = $("<button>").text(city);
								ul.append($("<li>").append(button));
							});

							// Append the ul element to the .mldb-search .ch-custom-input element
							$(".mldb-search .ch-custom-input").append(ul);
						},
					});
				} else {
					$(".mldb-search .ch-custom-input ul").remove();
				}
			}, 300);
		});

		form.on("submit", function (e) {
			e.preventDefault();
			var value = form.find("input").val();
			showResults(value, button);
		});

		$("body").on(
			"click",
			".mldb-search .autocomplete-dropdown button",
			function () {
				var value = $(this).text();

				$(".mldb-search input").val(value);
				$(".mldb-search .ch-custom-input ul").remove();

				showResults(value, button);
			}
		);
	}
});
